import { isToday } from "date-fns";
import {
  Item, Items, KitchenOrders, UpdateClientPasswordDto
} from "../../types";
import api from "../../configs/api";

export const getRealTimeOrders = async (entity: string): Promise<KitchenOrders> => {
  const { status, data } = await api.get(
    `/real-time/orders/${entity}`
  );

  if (status !== 200) throw new Error();

  return data;
};

export const getAllOrders = async (entity: string, getOnlyTodayOrders: boolean): Promise<KitchenOrders | false> => {
  try {
    const { status, data } = await api.get(
      `/client-order/entity/${entity}`
    );

    if (status !== 200) throw new Error();

    const orders = data
      // .filter((order: any) => order?.virtualCard?.client)
      .map((order: any, index: number) => {
        const {
          clientName,
          items,
          virtualCard,
          isOnCredit,
          ...rest
        } = order;

        const _items = items.map((item: any) => ({
          ...item.product,
          maxAmount: item.product.quantity,
          quantity: item.quantity,
          price: item.product.price,
          totalAmount: item.totalAmount,
        }));

        const client = !isOnCredit
          ? virtualCard?.client
          : {
            name: clientName,
            picture: null,
            id: null,
          };

        return {
          ...rest,
          _id: index + 1,
          client,
          items: _items,
          totalAmount: order.items.reduce((total: 0, item: Item) => total + item.totalAmount, 0),
        };
      });

    if (getOnlyTodayOrders) {
      return orders
        .filter((order: any) => !order?.isCanceled)
        .filter((order: any) => isToday(new Date(order.createdAt)));
    }

    return orders.filter((order: any) => !order?.isCanceled);
  } catch (error) {
    return false;
  }
};
export const getAllOrdersForScheduleOrder = async (entity: string): Promise<KitchenOrders | false> => {
  try {
    const { status, data } = await api.get(
      `/client-order/entity/scheduled/${entity}`
    );

    if (status !== 200) throw new Error();

    const orders = data
      // .filter((order: any) => order?.virtualCard?.client)
      .map((order: any, index: number) => {
        const {
          clientName,
          items,
          virtualCard,
          isOnCredit,
          ...rest
        } = order;

        const _items = items.map((item: any) => ({
          ...item.product,
          maxAmount: item.product.quantity,
          quantity: item.quantity,
          price: item.product.price,
          unitPrice: item.unitPrice,
          totalAmount: item.totalAmount,
          observation: item.observation,
        }));

        const client = !isOnCredit
          ? virtualCard?.client
          : {
            name: clientName,
            picture: null,
            id: null,
          };

        return {
          ...rest,
          _id: index + 1,
          client,
          items: _items,
          totalAmount: order.items.reduce((total: 0, item: Item) => total + item.totalAmount, 0),
        };
      });
    return orders;
  } catch (error) {
    return false;
  }
};

export const getAllDailyOrders = async (entity: string, getOnlyTodayOrders: boolean): Promise<KitchenOrders | false> => {
  try {
    const { status, data } = await api.get(
      `/client-order/entity/today/${entity}`
    );

    if (status !== 200) throw new Error();

    const orders = data
      // .filter((order: any) => order?.virtualCard?.client)
      .map((order: any, index: number) => {
        const {
          clientName,
          items,
          virtualCard,
          isOnCredit,
          ...rest
        } = order;

        const _items = items.map((item: any) => ({
          ...item.product,
          maxAmount: item.product.quantity,
          quantity: item.quantity,
          price: item.product.price,
          unitPrice: item.unitPrice,
          totalAmount: item.totalAmount,
          observation: item.observation,
        }));

        const client = !isOnCredit
          ? virtualCard?.client
          : {
            name: clientName,
            picture: null,
            id: null,
          };

        return {
          ...rest,
          _id: index + 1,
          client,
          items: _items,
          totalAmount: order.items.reduce((total: 0, item: Item) => total + item.totalAmount, 0),
        };
      });

    if (getOnlyTodayOrders) {
      return orders
        .filter((order: any) => !order?.isCanceled)
        .filter((order: any) => isToday(new Date(order.createdAt)));
    }

    return orders.filter((order: any) => !order?.isCanceled);
  } catch (error) {
    return false;
  }
};

export const editOrder = async ({ id, items }: { id: string, items: Items }) => {
  try {
    const { status, data } = await api.patch(`/client-order/items/${id}`, {
      items
    });

    if (status !== 200) throw new Error();

    return data;
  } catch (error) {
    return false;
  }
};

export const updateClient = async (dto: UpdateClientPasswordDto) => {
  try {
    const { status, data } = await api.put(
      `/clients/password/${dto.user}`,
      dto
    );

    if (status !== 200) throw new Error();

    return data;
  } catch (error) {
    return false;
  }
};

export const getSectors = async (id: string) => {
  const { status, data } = await api.get(
    `/segments/entity/${id}`
  );

  if (status !== 200) throw new Error();

  return data;
};

export const getSectorsActive = async (id: string) => {
  const { status, data } = await api.get(
    `/segments/entity/${id}/active`
  );

  if (status !== 200) throw new Error();

  return data;
};

export const getEntity = async (id: string) => {
  const { status, data } = await api.get(
    `/entity/${id}`
  );

  if (status !== 200) throw new Error();

  return data;
};

export const getEntityClients = async (entity: string) => {
  const { status, data } = await api.get(
    `/clients/entity/${entity}`
  );

  if (status !== 200) throw new Error();

  return data;
};

export const getClient = async (clientId: string) => {
  const { status, data } = await api.get(
    `/clients/${clientId}`
  );

  if (status !== 200) throw new Error();

  return data;
};
