import { Container } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import {
  CardOrder,
  EditRequest,
  OrderList,
  ProfileModal,
} from "../../components";
import { deliverOrder, progressOrder } from "../../services";
import { getFirebaseRealTimeDatabase } from "../../services/firebaseRealTimeDatabase";
import { useStore } from "../../store";
import {
  KitchenOrder,
  KitchenOrderClient,
  KitchenOrders,
  Sector,
} from "../../types";
import { getClientNameForOrder } from "../../utils/getClientNameForOrder";
import useStyles from "./styles";
import { useFetchRealTimeOrders, useGetSectorsActive } from "../../api/orders/hooks";
import { Select } from "../../components/Select";
import { useEntity } from "../../hooks/useEntity";
import CustomInput from "../../components/CustomInput";
import { getClientEnrollmentForOrder } from "../../utils/getClientEnrollmentForOrder";

export const RealTime: React.FC = () => {
  const { userManager, navigation } = useStore();

  const [entityId, setEntityId] = useState<string>("");
  const [filterName, setFilterName] = useState<string>("");

  const queryClient = useQueryClient();
  const location = useLocation();
  const { showSectors } = useEntity();
  const { mutateAsync: patchOrderMutator, isLoading } = useMutation(progressOrder);
  const { mutateAsync: deliveryOrderMutator, isLoading: isloadingDelivery } = useMutation(deliverOrder);

  const { data: _orders, refetch: reFetchOrders } = useFetchRealTimeOrders(entityId);

  const { data: sectors } = useGetSectorsActive(entityId, true);

  const Styles = useStyles();

  const [selectedOrder, setSelectedOrder] = useState<KitchenOrder | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [clientProfile, setClientProfile] = useState<KitchenOrderClient | null>(null);
  const [pendingOrders, setPendingOrders] = useState<KitchenOrders>([]);
  const [progressOrders, setProgressOrders] = useState<KitchenOrders>([]);
  const [sectorsList, setSectorsList] = useState<any[]>([]);
  const [sectorSelected, setSectorSelected] = useState("all");
  const [sectorId, setSectorId] = useState("all");
  const isRealTimeRoute = useCallback(() => location.pathname === "/real-time", [location.pathname]);

  const filterOrdersBySector = (order: any) => {
    if (sectorId === "all") return true;

    if (sectorId === "others") {
      return order.items?.some((item: any) => !item.segment);
    }

    return order.items?.some((item: any) => item.segment === sectorId);
  };

  const filterOrdersByName = (order: KitchenOrder) => {
    const clientName = getClientNameForOrder(order).toLowerCase();
    return clientName.includes(filterName.toLowerCase());
  };

  const loadOrders = async () => {
    if (isRealTimeRoute()) {
      if (_orders) {
        if (_orders.pendingOrders) {
          setPendingOrders(
            _orders.pendingOrders
              .filter((order: any) => filterOrdersBySector(order))
              .filter((order: any) => filterOrdersByName(order))
          );
        } else {
          setPendingOrders([]);
        }
        if (_orders.progressOrders) {
          setProgressOrders(
            _orders.progressOrders
              .filter((order: any) => filterOrdersBySector(order))
              .filter((order: any) => filterOrdersByName(order))
          );
        } else {
          setProgressOrders([]);
        }
      }
    }
  };

  const handleOpenModal = (order: KitchenOrder) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };

  const formatList = () => {
    const listSectors = sectors?.map((_sector: Sector) => ({
      label: _sector.name,
      value: _sector.id,
    }));

    const listSortedSectors: any[] = listSectors?.sort((a: any, b: any) =>
      a.label.localeCompare(b.label));
    listSortedSectors.unshift({ label: "Todos", value: "all" });
    listSortedSectors.push({ label: "Outros", value: "others" });

    setSectorsList(listSortedSectors);
  };

  const filterOrders = (Id: string) => {
    setSectorSelected(Id);
    setSectorId(Id);
    loadOrders();
  };

  const handleDeliverOrder = async (id: string) => {
    await deliveryOrderMutator(id);
    await queryClient.invalidateQueries([
      "todayOrders",
      userManager?.entity,
      true,
    ]);
  };

  const handleCancelOrder = (order: KitchenOrder) => {
    navigation.navigateTo("/login-admin", {
      state: {
        order,
      },
    });
  };

  const handleProgressOrder = async (id: string) => {
    await patchOrderMutator(id);
    await queryClient.invalidateQueries([
      "todayOrders",
      userManager?.entity,
      true,
    ]);
  };

  useEffect(() => {
    (async () => {
      await loadOrders();
    })();
  }, [_orders, sectorId, filterName]);

  useEffect(() => {
    if (userManager.entity) {
      setEntityId(userManager.entity);
    }
  }, [userManager]);

  useEffect(() => {
    getFirebaseRealTimeDatabase(userManager.entity, async () => {
      if (userManager.role === 'kitchen') {
        await reFetchOrders();
      }
    });
  }, []);

  useEffect(() => {
    if (sectors?.length) {
      formatList();
    }
  }, [sectors]);

  return (
    <Container maxWidth={false} className={Styles.container}>
      <>
        <div className={Styles.filterSector}>
          {sectorsList?.length > 0 && showSectors && (
          <Select
            label="Setor"
            options={sectorsList}
            value={sectorSelected}
            onChange={(val) => filterOrders(val)}
            labelStyle={Styles.label}
          />
          )}
          <CustomInput
            label="Buscar por nome"
            labelColor="secondary"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
        </div>

        <hr className={Styles.line} />
      </>

      <div className={Styles.warpper}>
        <OrderList title="Pendentes">
          {pendingOrders.map((order) => (
            <CardOrder
              {...order}
              name={getClientNameForOrder(order)}
              enrollment={getClientEnrollmentForOrder(order)}
              key={order.id}
              orderAction={handleProgressOrder}
              cancelOrder={() => handleCancelOrder(order)}
              selectOrder={() => handleOpenModal(order)}
              handleProfileClick={() => setClientProfile(order.client)}
              isLoading={isLoading || isloadingDelivery}
            />
          ))}
        </OrderList>

        <OrderList title="Em Andamento">
          {progressOrders.map((order) => (
            <CardOrder
              {...order}
              name={getClientNameForOrder(order)}
              enrollment={getClientEnrollmentForOrder(order)}
              key={order.id}
              orderAction={handleDeliverOrder}
              cancelOrder={() => handleCancelOrder(order)}
              selectOrder={() => handleOpenModal(order)}
              handleProfileClick={() => setClientProfile(order.client)}
              isLoading={isLoading || isloadingDelivery}
            />
          ))}
        </OrderList>

        {clientProfile && (
          <ProfileModal
            open={!!clientProfile}
            onClose={() => setClientProfile(null)}
            {...clientProfile}
          />
        )}

        {selectedOrder && (
          <EditRequest
            order={selectedOrder}
            open={openModal}
            onClose={handleCloseModal}
            disableProductsActionButtons
          />
        )}
      </div>
    </Container>
  );
};
